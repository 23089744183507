// MainForm.tsx
import React, { useState, useEffect } from "react";
import formJSON from "./musikspelet-2024.json";
import { update, get, ref, onChildAdded } from "firebase/database"; // Import Firebase functions
import { fbDb } from "./firebase"; // Import your Firebase config
import { useUser } from "./UserContext";
import "../JudgeForm.scss";
import JudgeSection from "./JudgeSection";
import Scoreboard from "./Scoreboard";

const JudgeForm: React.FC = () => {
	const [formData, setFormData] = useState<
		Record<string, { userId: string; teamName: string; data: string }>
	>({});
	const [teamNames, setTeamNames] = useState<Record<string, string>>({});
	const user = useUser();

	const handleChange = (id: string, value: string) => {
		/* setFormData({
			...formData,
			[id]: value,
		});
		console.log(formData); */
	};

	const updateUserRole = () => {
		if (user) {
			const superUserRef = ref(fbDb, `users/${user.uid}`);
			get(superUserRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const userData = snapshot.val();

						// Check if the "role" field exists and is an array
						if (Array.isArray(userData.role)) {
							// Add "SUPERUSER" if it doesn't already exist
							if (!userData.role.includes("SUPERUSER")) {
								userData.role.push("SUPERUSER");
							}
						} else {
							// Initialize "role" as an array containing "SUPERUSER"
							userData.role = ["SUPERUSER"];
						}

						// Update the user data
						update(superUserRef, { role: userData.role });
					} else {
						console.log("No data available");
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	useEffect(() => {
		if (user) {
			const db = fbDb;
			const usersRef = ref(db, `users`);

			//updateUserRole();
			const superUserRef = ref(fbDb, `users/${user.uid}`);
			update(superUserRef, {
				role: "SUPERUSER",
			});

			// Listen for changes in the database
			const unsubscribe = onChildAdded(usersRef, (snapshot) => {
				//const unsubscribe = onValue(usersRef, (snapshot) => {
				const userId: string = snapshot.key ?? ""; // Get the userId from the snapshot
				const userData = snapshot.val(); // Get the user data

				/* console.log("Judgeform userdata is: ", userData.teamName);
				console.log(userId);*/
				/* console.log("The users role is: ", userData.role);
				if (Array.isArray(userData.role)) {
					// Add "SUPERUSER" if it doesn't already exist
					if (userData.role.includes("PARTICIPANT")) {
						
					}
				} */
				console.log(userData.teamName);
				if (userData.teamName !== "" && userData.teamName !== undefined) {
					setFormData((prevFormData) => ({
						...prevFormData,
						[userId]: { userId, teamName: userData.teamName, data: userData },
					}));
				}
			});

			// Cleanup function to unsubscribe from the database changes
			return () => unsubscribe();
		}
	}, [user]);

	return (
		<div className="judge-wrapper">
			<div className="score-wrap">
				<div className="score-board-wrapper">
					<h1>Ställning:</h1>
					{Object.entries(formData).map(([key, teamIndex]) => (
						<div className="score-single-team" key={key}>
							<Scoreboard userId={key} teamName={formData[key].teamName} />
						</div>
					))}
				</div>
			</div>
			<div id="teams-wrapper">
				{Object.entries(formData).map(([key, teamIndex]) => (
					<div className="single-team" key={key}>
						<div className="single-team-title">{formData[key].teamName}</div>
						<div className="single-team-content">
							{formJSON.sections.map((section, index) => (
								<JudgeSection
									key={teamIndex + "-" + index}
									userId={key}
									title={section.title}
									sectionIndex={index}
									subtitle={section.subtitle}
									questions={section.questions}
									onChange={handleChange}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default JudgeForm;
