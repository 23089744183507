// Question.tsx
interface QuestionProps {
	title: string;
	id: string;
	value: string;
	questionIndex: string;
	onChange: (id: string, value: string) => void;
}

const Question: React.FC<QuestionProps> = ({
	title,
	id,
	value,
	onChange,
	questionIndex,
}) => {
	return (
		<div className="inputDiv">
			<label htmlFor={id}>{title}: </label>
			<input
				id={id}
				type="text"
				value={value}
				onChange={(e) => onChange(questionIndex, e.target.value)}
				className="my-input"
			/>
		</div>
	);
};

export default Question;
