// Section.tsx
import React, { useState, useEffect } from "react";
import { set, ref, onValue } from "firebase/database"; // Import Firebase functions
import { fbDb } from "./firebase"; // Import your Firebase config
import Question from "./Question";
import { useUser } from "./UserContext";
import "./Section.scss";

interface SectionProps {
	userId: string;
	title: string;
	sectionIndex: number;
	subtitle?: string;
	questions: { title: string; points: number; onlyJudge?: boolean }[]; // Add 'points' property here
	repeats?: number;
	onChange: (id: string, value: string) => void;
	formDataprop: Record<string, string>;
}

interface FormDataItem {
	questionIndex: number;
	value: string;
	correct: boolean;
}

const Section: React.FC<SectionProps> = ({
	userId,
	title,
	sectionIndex,
	subtitle,
	questions,
	repeats,
	onChange,
	formDataprop,
}) => {
	//const [formData, setFormData] = useState<Record<string, string>>({});
	const [formData, setFormData] = useState<FormDataItem[]>([]);
	const [submitted, setSubmitted] = useState(false);
	const user = useUser();
	const handleSubmit = () => {
		// Prepare the data for this section
		if (userId !== "unset") {
			// Transform the original object into the desired format
			// Transform the original object into the desired format
			const transformedData = Object.keys(formData).reduce(
				(acc, key, index) => {
					acc[key] = {
						value: formData[index].value,
						questionIndex: index,
						corrected: false, // You can set 'corrected' to false by default
					};
					return acc;
				},
				{} as Record<
					string,
					{
						value: string;
						questionIndex: number;
						corrected: boolean;
					}
				>
			);
			/* console.log("formData before sending it to database: ", transformedData); */
			const specificReference = ref(fbDb, `users/${userId}/${sectionIndex}`);
			set(specificReference, {
				sectionData: transformedData,
				timeStamp: Date.now(),
				submitted: true,
			});
		}
	};
	const handleChange = (id: string, value: string) => {
		/* console.log(formData);
		console.log(id);
		console.log(value); */
		setFormData({
			...formData,
			[id]: {
				value: value,
				questionIndex: id,
				correct: false,
			},
		});
	};

	useEffect(() => {
		if (user) {
			const db = fbDb;
			const formRef = ref(db, `users/${userId}/${sectionIndex}`);

			// Listen for changes in the database
			const unsubscribe = onValue(formRef, (snapshot) => {
				const data = snapshot.val();

				// Iterate through the `sectionData` array and build the object
				const transformedBackData: Record<string, string> = {};
				if (data) {
					/* console.log("The fetched data is: ", data.sectionData);
					console.log("Submitted?: ", data.submitted); */
					setFormData(data.sectionData);
					setSubmitted(data.submitted);
					/* data.sectionData.forEach(
						(
							item: {
								value: string;
								questionIndex: number;
								correct: boolean;
							},
							index: number
						) => {
							transformedBackData[item.questionIndex.toString()] = item.value;
						}
					);
				} */
				}
				//setFormData(transformedBackData);
				//console.log("Transformed back data: ", transformedBackData);
			});

			// Cleanup function to unsubscribe from the database changes
			return () => unsubscribe();
		}
	}, [user]);

	return (
		<section className="wrapperSection">
			<h1>{title}</h1>
			{subtitle && <h2>{subtitle}</h2>}
			<div className="inputHolder">
				{repeats
					? Array.from({ length: repeats }, (_, i) => {
							const id = `${title}-${i}`;
							const value = formData[i] ? formData[i].value : "";
							//console.log("Formdata is: ", formData[title]);
							//console.log("id is: ", id);
							return (
								<Question
									key={i}
									title={`${questions[0].title} ${i + 1}`}
									id={`${userId}+${id}`}
									questionIndex={`${i}`}
									value={value || ""}
									onChange={handleChange}
								/>
							);
					  })
					: questions.map((q, index) => {
							const id = `${title}-${index}`;
							const value = formData[index] ? formData[index].value : "";
							if (!q.onlyJudge) {
								return (
									<Question
										key={index}
										title={q.title}
										id={`${userId}+${id}`}
										questionIndex={`${index}`}
										value={value || ""}
										onChange={handleChange}
									/>
								);
							} else {
								return <div></div>;
							}
					  })}
			</div>
			{submitted ? (
				<div>Ditt svar har skickats!</div>
			) : (
				<button onClick={handleSubmit}>Skicka in svar!</button>
			)}
		</section>
	);
};

export default Section;
