// MainForm.tsx
import React, { useState, useEffect } from "react";
import formJSON from "./musikspelet-2024.json";
import Section from "./Section";
import { get, update, set, ref, onValue } from "firebase/database"; // Import Firebase functions
import { fbDb } from "./firebase"; // Import your Firebase config
import { useUser } from "./UserContext";

const MainForm: React.FC = () => {
	const [formData, setFormData] = useState<Record<string, string>>({});
	const [teamName, setTeamName] = useState("");
	const [teamNameHasBeenSet, setTeamNameHasBeenSet] = useState(false);
	const user = useUser();

	const handleChange = (id: string, value: string) => {
		setFormData({
			...formData,
			[id]: value,
		});
		/* console.log("Formdata is: ", formData);
		console.log("Handle change is run, id: ", id);
		console.log("Handle change is run, value: ", value); */
	};

	const onTeamNameChange = (name: string) => {
		setTeamName(name);
	};

	const submitTeamName = () => {
		if (user) {
			const db = fbDb;
			const formRef = ref(db, `users/${user.uid}/teamName`);
			set(formRef, teamName);
			setTeamNameHasBeenSet(true);

			//updateUserRole();
		}
	};

	const updateUserRole = () => {
		if (user) {
			const superUserRef = ref(fbDb, `users/${user.uid}`);
			get(superUserRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const userData = snapshot.val();

						// Check if the "role" field exists and is an array
						if (Array.isArray(userData.role)) {
							// Add "SUPERUSER" if it doesn't already exist
							if (!userData.role.includes("PARTICIPANT")) {
								userData.role.push("PARTICIPANT");
							}
						} else {
							// Initialize "role" as an array containing "SUPERUSER"
							userData.role = ["PARTICIPANT"];
						}

						// Update the user data
						update(superUserRef, { role: userData.role });
					} else {
						console.log("No data available");
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	useEffect(() => {
		if (user) {
			const db = fbDb;
			const formRef = ref(db, `users/${user.uid}/teamName`);

			// Listen for changes in the database
			const unsubscribe = onValue(formRef, (snapshot) => {
				const data = snapshot.val();
				if (data) {
					setTeamName(data);
					setTeamNameHasBeenSet(true);
				}
			});

			// Cleanup function to unsubscribe from the database changes
			return () => unsubscribe();
		}
	}, [user]);

	return (
		<div>
			<div className="wrapperSection">
				{teamNameHasBeenSet ? (
					<h1>Lagnamn: {teamName}</h1>
				) : (
					<div>
						<h1>Lagnamn: </h1>
						<h2>
							Fyll i önskat lagnamn nedan och klicka på "Anmäl lag!". Det går
							inte att ändra i efterhand så tänk efter noggrant!{" "}
						</h2>
						<div className="inputDiv">
							<input
								type="text"
								value={teamName}
								onChange={(e) => onTeamNameChange(e.target.value)}
								className="my-input"
							/>
						</div>
						<button onClick={submitTeamName}>Anmäl lag!</button>
					</div>
				)}
			</div>
			{formJSON.sections.map((section, index) => (
				<Section
					key={index}
					userId={user ? user.uid : "unset"}
					title={section.title}
					sectionIndex={index}
					subtitle={section.subtitle}
					questions={section.questions}
					onChange={handleChange}
					formDataprop={formData}
				/>
			))}
		</div>
	);
};

export default MainForm;
