import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from "react";
import { signInAnonymously, onAuthStateChanged, User } from "firebase/auth";

import { fbAuth } from "./firebase"; // Adjust the import to your file structure

interface UserProviderProps {
	children: ReactNode;
}

export const UserContext = createContext<User | null>(null);

export const useUser = () => {
	return useContext(UserContext);
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const auth = fbAuth;
		signInAnonymously(auth).catch((error) => {
			console.error("Error during anonymous sign-in:", error);
		});

		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setUser(user);
		});

		return () => unsubscribe();
	}, []);

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
