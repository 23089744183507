// Section.tsx
import React, { useState, useEffect } from "react";
import { get, update, set, ref, onValue } from "firebase/database"; // Import Firebase functions
import { fbDb } from "./firebase"; // Import your Firebase config
import Question from "./Question";
import { useUser } from "./UserContext";
import JudgeQuestion from "./JudgeQuestion";

interface SectionProps {
	userId: string;
	title: string;
	sectionIndex: number;
	subtitle?: string;
	questions: { title: string; points: number; answer: string }[]; // Add 'points' property here
	repeats?: number;
	onChange: (id: string, value: string) => void;
}

interface FormDataItem {
	correct: boolean;
	questionIndex: number;
	value: string;
}

const JudgeSection: React.FC<SectionProps> = ({
	userId,
	title,
	sectionIndex,
	subtitle,
	questions,
	repeats,
	onChange,
}) => {
	const [formData, setFormData] = useState<FormDataItem[]>([]);
	const [submitted, setSubmitted] = useState(false);
	const [timeStamp, setTimeStamp] = useState("");
	const [totalPointsForSection, setTotalPointsForSection] = useState(0);

	const user = useUser();

	const handleChange = (
		questionIndex: string,
		correct: boolean,
		value: string,
		numberOfPoints: number
	) => {
		/* console.log("Questionindex: ", questionIndex);
		console.log("Corrected: ", correct);
		console.log("formdata: ", formData); */

		const singleData = {
			value: value,
			questionIndex: questionIndex,
			correct: correct,
		};

		//console.log("formData before sending it to database: ", transformedData);
		const specificReference = ref(
			fbDb,
			`users/${userId}/${sectionIndex}/sectionData/${questionIndex}`
		);
		set(specificReference, singleData);

		const scoreRef = ref(fbDb, `users/${userId}/score/${sectionIndex}`);
		get(scoreRef).then((snapshot) => {
			const sectionData = snapshot.val();
			/* console.log("Score data: ", sectionData); */
			if (correct) {
				// Update totalPointsForSection by adding numberOfPoints to the current value
				if (sectionData !== null) {
					set(scoreRef, sectionData + numberOfPoints);
				} else {
					set(scoreRef, numberOfPoints);
				}
			} else {
				if (sectionData !== null) {
					set(scoreRef, sectionData - numberOfPoints);
				} else {
					set(scoreRef, numberOfPoints);
				}
			}
		});

		const scoreData = {
			numberOfPoints: correct ? numberOfPoints : 0,
			questionIndex: questionIndex,
		};

		const scoreBoardReference = ref(
			fbDb,
			`score/${userId}/${sectionIndex}/${questionIndex}`
		);
		set(scoreBoardReference, scoreData);

		/* setFormData({
			...formData,
			[id]: value,
		}); */
	};

	useEffect(() => {
		if (user) {
			const db = fbDb;
			const formRef = ref(db, `users/${userId}/${sectionIndex}`);

			// Listen for changes in the database
			const unsubscribe = onValue(formRef, (snapshot) => {
				const data = snapshot.val();

				// Iterate through the `sectionData` array and build the object
				const transformedBackData: Record<string, string> = {};
				if (data) {
					/* console.log("The fetched data is: ", data.sectionData); */
					if (data.sectionData && Array.isArray(data.sectionData)) {
						data.sectionData.forEach(
							(
								item: {
									value: string;
									questionIndex: number;
									corrected: boolean;
								},
								index: number
							) => {
								transformedBackData[item.questionIndex.toString()] = item.value;
							}
						);
						setFormData(data.sectionData);
						setSubmitted(data.submitted);
						const d = new Date(data.timeStamp);
						const datestring =
							"Inlämnat: " +
							d.getHours() +
							":" +
							d.getMinutes() +
							":" +
							d.getSeconds() +
							":" +
							d.getMilliseconds();
						setTimeStamp(datestring);
					}
				}
				//console.log("Transformed back data: ", transformedBackData);
			});

			// Cleanup function to unsubscribe from the database changes
			return () => unsubscribe();
		}
	}, [user]);

	const openAnswer = () => {
		// Prepare the data for this section
		if (userId !== "unset") {
			const specificReference = ref(
				fbDb,
				`users/${userId}/${sectionIndex}/submitted`
			);
			set(specificReference, false);

			const scoreRef = ref(fbDb, `users/${userId}/score/${sectionIndex}`);
			set(scoreRef, 0);

			const moreRef = ref(fbDb, `users/${userId}/${sectionIndex}`);

			get(moreRef).then((snapshot) => {
				const userData = snapshot.val();
				/* console.log(userData); */
				// Update the 'correct' field in each sectionData object
				const sectionData = userData.sectionData;
				for (const question of sectionData) {
					question.correct = false;
				}

				// Update the object back in Firebase
				update(moreRef, { sectionData });
			});
		}

		setTimeStamp("");
	};

	if (!submitted) {
		return (
			<section className="wrapperSection">
				<h1>{title}</h1>
				<p>Inväntar svar!</p>
			</section>
		);
	}

	return (
		<section className="wrapperSection">
			<h1>{title}</h1>
			<div className="inputHolder">
				{repeats
					? Array.from({ length: repeats }, (_, i) => {
							const id = `${title}-${i}`;
							//console.log("Formdata is: ", formData);
							//console.log("Formdata[i] is: ", formData[i]);
							const value = formData[i] ? formData[i].value : "";
							const correct = formData[i] ? formData[i].correct : false;
							const numberOfPoints = questions[0].points
								? questions[0].points
								: 1;
							/* console.log("Corrected: ", formData[i] ?? ""); */

							//console.log("id is: ", id);
							return (
								<JudgeQuestion
									key={i}
									title={`${questions[0].title} ${i + 1}`}
									answer={`${questions[0].answer}`}
									id={`${userId}+${id}`}
									questionIndex={`${i}`}
									value={value || ""}
									onChange={handleChange}
									initialCorrected={correct}
									numberOfPoints={numberOfPoints}
								/>
							);
					  })
					: questions.map((q, index) => {
							const id = `${title}-${index}`;
							const value = formData[index] ? formData[index].value : "";
							const correct = formData[index] ? formData[index].correct : false;
							const numberOfPoints = q.points ? q.points : 1;

							return (
								<JudgeQuestion
									key={index}
									title={q.title}
									answer={q.answer}
									id={`${userId}+${id}`}
									questionIndex={`${index}`}
									value={value || ""}
									onChange={handleChange}
									initialCorrected={correct}
									numberOfPoints={numberOfPoints}
								/>
							);
					  })}
			</div>
			<div>{timeStamp}</div>
			{submitted ? <button onClick={openAnswer}>Öppna svar</button> : ""}
		</section>
	);
};

export default JudgeSection;
