import React, { useEffect, useState } from "react";
import { ref, onValue, DataSnapshot } from "firebase/database";
import { fbDb } from "./firebase"; // Import your Firebase config

interface ScoreData {
	numberOfPoints: number;
	questionIndex: number;
}

interface ScoreBoardProps {
	userId: string;
	teamName: string;
}

const Scoreboard: React.FC<ScoreBoardProps> = ({ userId, teamName }) => {
	const [totalScore, setTotalScore] = useState<number>(0);

	useEffect(() => {
		// Initialize Firebase database reference
		const scoreBoardReference = ref(fbDb, `users/${userId}/score`);

		// Subscribe to value changes
		const unsubscribe = onValue(
			scoreBoardReference,
			(snapshot: DataSnapshot) => {
				const data = snapshot.val();
				/* console.log("Scoreboard data is: ", data); */
				if (data) {
					let newTotalScore = 0;
					if (Array.isArray(data)) {
						data.forEach((item, index) => {
							/* console.log(`Array Item ${index}: `, item); */
							newTotalScore += item;
						});
					} else {
						Object.keys(data).forEach((key) => {
							/* console.log(`Object Key ${key}: `, data[key]); */
							newTotalScore += data[key];
						});
					}
					setTotalScore(newTotalScore);
				}
			}
		);

		// Cleanup: Unsubscribe from changes when the component unmounts
		return () => {
			unsubscribe();
		};
	}, [userId]);

	return (
		<div className="scoreboard-team-wrapper">
			<p>{teamName}:</p>
			<p>{totalScore}</p>
		</div>
	);
};

export default Scoreboard;
