import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyBE12yLynBzMZrEb8FjzJYHVVouqmQ-gn0",
	authDomain: "musikspelet.firebaseapp.com",
	databaseURL:
		"https://musikspelet-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "musikspelet",
	storageBucket: "musikspelet.appspot.com",
	messagingSenderId: "555127128932",
	appId: "1:555127128932:web:047d4e454b8366433b3071",
	measurementId: "G-EWL5SCPX0G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const fbDb = getDatabase(app);
const fbAuth = getAuth(app);

export { fbDb, fbAuth };
