// App.tsx
import React from "react";
import "./App.scss";
import { UserProvider } from "./Components/UserContext";
import MainForm from "./Components/MainForm";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import JudgeForm from "./Components/JudgeForm";

const router = createBrowserRouter(
	[
		{
			path: `/`,
			element: <MainForm />,
		},
		{
			path: `/judge`,
			element: <JudgeForm />,
		},
	],
	{ basename: "/" }
);

const App: React.FC = () => {
	return (
		<UserProvider>
			<div className="App">
				<header className="App-header">
					<RouterProvider router={router} />
				</header>
			</div>
		</UserProvider>
	);
};

export default App;
