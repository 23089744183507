import React, { useState, useEffect } from "react";

interface JudgeQuestionProps {
	title: string;
	answer: string;
	id: string;
	value: string;
	questionIndex: string;
	initialCorrected: boolean;
	onChange: (
		questionIndex: string,
		corrected: boolean,
		value: string,
		numberOfPoints: number
	) => void;
	numberOfPoints: number;
}

const JudgeQuestion: React.FC<JudgeQuestionProps> = ({
	title,
	answer,
	id,
	value,
	questionIndex,
	initialCorrected,
	onChange,
	numberOfPoints,
}) => {
	const safeInitialCorrected =
		initialCorrected !== undefined ? initialCorrected : false;

	// Local state to manage checkbox
	const [corrected, setCorrected] = useState(safeInitialCorrected);

	useEffect(() => {
		// Update local state when prop changes
		setCorrected(safeInitialCorrected);
	}, [safeInitialCorrected]);

	// Function to handle checkbox change
	const handleCheckboxChange = () => {
		const newCorrected = !corrected; // Toggle state
		setCorrected(newCorrected); // Update local state
		onChange(questionIndex, newCorrected, value, numberOfPoints); // Update parent
	};

	return (
		<div className="questionDiv">
			<div>
				<h2>{title}:</h2>
				<p>
					Lagets svar: {value}
					<input
						type="checkbox"
						checked={corrected} // Use local state
						onChange={handleCheckboxChange}
					/>
					<br />
					<span className="correct-answer">
						<i>(Rätt svar): {answer}</i>
					</span>
				</p>
			</div>
		</div>
	);
};

export default JudgeQuestion;
